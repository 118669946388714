<template>
  <MiscellaneousDashboard dialog-name="Dashboards" />
</template>
<script>
import MiscellaneousDashboard from '@/views/app/miscellaneous/Dashboard'

export default {
  name: 'DashboardWrapper',
  components: {
    MiscellaneousDashboard
  }
}
</script>
